.mapboxgl-gc-controls {
	button {
		background-color: rgba(255, 255, 255, 0.1);
		backdrop-filter: blur(2px);
	}
}

.mapboxgl-control-container {

	.mapboxgl-ctrl-top-right {
		@extend .mapboxgl-gc-controls
	}

	.mapboxgl-ctrl-bottom-right {
		@extend .mapboxgl-gc-controls
	}
}
