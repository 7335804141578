.mapboxgl-popup.mapbox-popup-vesselname {
	.mapboxgl-popup-tip {
		border: 6px solid transparent;
		border-top-color: rgba(100, 100, 100, 1);
		border-radius: 20px;
		height: 0px;
		width: 0px;
		margin-top: -1px;
	}

	.mapboxgl-popup-content {
		background-color: rgba(100, 100, 100, 1);
		border-radius: 5px;
		// border: 1px solid rgba(255, 255, 255, 0.5);
		padding: 8px;
		color: white;
		font-size: 11px;
		line-height: 1;
	}
}
